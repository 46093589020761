import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Button, IconButton } from '@mui/material';
import {
  Close as CloseIcon,
  Fullscreen as FullscreenIcon,
  Refresh as RefreshIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import urltron from 'urltron';

import { Class as NotificationClass, NotificationsFilterField } from '@playq/octopus-notifications';
import { OptionsFilter } from '@playq/octopus-common';

import { EntityAnnotations } from '/common/AppTimeline/Timeline/Annotations';
import { appToolkit, currentModuleToolkit, initialTimelineState, ITimelineState, uiToolkit } from '/store';
import { IManualTogglingParams, SidebarSide } from '/shared/Sidebar';
import { createStorage, localStorageMethods } from '/storage';
import { QueryHelpers } from '/helpers/query';
import { Resizable } from '/shared/Resizable';
import { QueryFilterSerialized } from '/common/models';
import { navSidebarWidth } from '/common/NavSidebar/constants';

import { getInitialIterator, getInitialState, notificationsService, useTimeline } from './Timeline';
import { TimelineState } from './Timeline/types';
import { ToolTip } from './Timeline/ToolTip';
import { ControlBar, StyledSidebar, TimelineStyled, Wrapper } from './styles';
import { MIN_TIMELINE_WIDTH, SAFE_RESIZE_THRESHOLD, TIMELINE_WIDTH } from './constants';

const storage = createStorage({ storage: localStorageMethods });

const compressFilterClasses = (filterBy: TimelineState['query']['filterBy']): Record<string, unknown> => {
  const notifications = Object.values(NotificationClass) as string[];
  const classFilter = filterBy[NotificationsFilterField.Class] as OptionsFilter | undefined;
  if (!classFilter) {
    return filterBy;
  }
  const shouldInclude = notifications.length - classFilter.options.length > notifications.length / 2;
  const { [NotificationsFilterField.Class]: tmp, ...rest } = filterBy;
  return {
    ...QueryHelpers.serializeFilterBy(rest),
    classes: {
      options: shouldInclude ? classFilter.options : notifications.filter((c) => !classFilter.options.includes(c)),
      include: shouldInclude,
    },
  };
};

const updateModuleStateFilterBy = (filterBy: QueryFilterSerialized) => (prevState: TimelineState) => ({
  ...prevState,
  query: {
    ...prevState.query,
    filterBy: QueryHelpers.deserializeFilterBy(filterBy),
  },
});

export const AppTimeline = () => {
  const app = useSelector(appToolkit.selectors.app);
  const appRouteName = useSelector(appToolkit.selectors.appRouteName);
  const timelineToolkitState = useSelector(uiToolkit.selectors.timeline);
  const { open, searchMode, filterBy, moduleTimelineSelected } = timelineToolkitState;
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const setTimelineToolkitState = useCallback(
    (val: Partial<ITimelineState>) => dispatch(uiToolkit.actions.setTimelineState(val)),
    [dispatch]
  );

  const queryClient = useQueryClient();

  const [appModuleState, setAppModuleState] = useState<TimelineState>(getInitialState(false));
  const [moduleState, setModuleState] = useState<TimelineState>(getInitialState(true));

  const prevOpen = useRef<boolean>(open);
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const intialTimelineWidth = useRef<number>(
    storage.get('timeline-width') !== null ? parseInt(storage.get('timeline-width') ?? '0') : TIMELINE_WIDTH
  );

  const setTimelineState = moduleTimelineSelected ? setModuleState : setAppModuleState;
  const { timelineModule, moduleClass, moduleRouteName } = useTimeline({
    pathname: location.pathname,
    setTimelineState,
  });

  useEffect(() => {
    if (open === false && prevOpen.current === true) {
      removeMinWidthStyle();
    }
    prevOpen.current = open;
  }, [open]);

  useEffect(
    function updateStateFilterByWithToolkitFilterBy() {
      setTimelineState(updateModuleStateFilterBy(filterBy));
    },
    // should be done only one time
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterBy]
  );

  const url = useMemo(() => {
    const { [NotificationsFilterField.Class]: tmp, ...moduleFilterBy } = moduleState.query.filterBy;
    const moduleIdx = location.pathname.indexOf(moduleRouteName);
    const initialModuleUrl = timelineModule
      ? location.pathname
      : `${location.pathname.slice(0, moduleIdx)}${moduleRouteName}`;
    return {
      app: appRouteName
        ? `/apps/${appRouteName}/app-timeline?${urltron.stringify({
            ...appModuleState.query,
            filterBy: compressFilterClasses(appModuleState.query.filterBy),
            iterator: appModuleState.query.iterator.serialize(),
          })}`
        : '',
      module: `${initialModuleUrl}/timeline?${urltron.stringify({
        ...moduleState.query,
        filterBy: QueryHelpers.serializeFilterBy(moduleFilterBy),
        iterator: moduleState.query.iterator.serialize(),
      })}`,
    };
  }, [moduleState.query, location.pathname, moduleRouteName, timelineModule, appRouteName, appModuleState.query]);

  const timelineManualTogglingParams = useMemo<IManualTogglingParams>(
    () => ({
      isOpenedManually: open,
      openManually: () => setTimelineToolkitState({ open: true }),
      closeManually: () => {
        setTimelineToolkitState(initialTimelineState);
        setAppModuleState(getInitialState(false));
        setModuleState(getInitialState(true));
      },
    }),
    [open, setTimelineToolkitState]
  );

  const handleSearchModeToggle = () => {
    const nextFilterVisible = !searchMode;
    if (timelineModule && moduleTimelineSelected) {
      setModuleState(getInitialState(true));
    } else {
      setAppModuleState(getInitialState(false));
    }
    setTimelineToolkitState({ searchMode: nextFilterVisible });
  };

  const clearStateIterator = (state: TimelineState) => ({
    ...state,
    query: {
      ...state.query,
      iterator: getInitialIterator(),
    },
  });

  const handleRefresh = () => {
    let state = appModuleState;
    let currentService = notificationsService;
    if (timelineModule && moduleTimelineSelected) {
      state = clearStateIterator(moduleState);
      setModuleState(state);
      currentService = timelineModule.service;
    } else {
      state = clearStateIterator(appModuleState);
      setAppModuleState(state);
    }
    queryClient.invalidateQueries(
      [
        currentService.getFullClassName(),
        'queryNotifications',
        app?.id,
        state.query.iterator,
        state.query.sortBy,
        state.query.filterBy,
      ],
      {
        exact: true,
      }
    );
  };

  const handleQueryReset = () => {
    let initState = getInitialState(false);
    let currentService = notificationsService;

    if (timelineModule && moduleTimelineSelected) {
      initState = getInitialState(true);
      setModuleState(initState);
      currentService = timelineModule.service;
    } else {
      setAppModuleState(initState);
    }

    queryClient.invalidateQueries(
      [
        currentService.getFullClassName(),
        'queryNotifications',
        app?.id,
        initState.query.iterator,
        initState.query.sortBy,
        initState.query.filterBy,
      ],
      {
        exact: true,
      }
    );
  };

  const removeMinWidthStyle = () => {
    sidebarRef.current?.style.removeProperty('width');
  };

  const setSidebarWidth = useCallback((sidebar: HTMLElement, width: number) => {
    let newWidth = Math.min(
      Math.max(width, MIN_TIMELINE_WIDTH),
      document.body.clientWidth - navSidebarWidth - SAFE_RESIZE_THRESHOLD
    );

    // stick new width to the default width
    if (newWidth > TIMELINE_WIDTH - 100 && newWidth < TIMELINE_WIDTH + 100) {
      newWidth = TIMELINE_WIDTH;
    }

    sidebar.style.width = `${newWidth}px`;
    storage.set('timeline-width', newWidth.toString());
    intialTimelineWidth.current = newWidth;
  }, []);

  const handleModuleToggle = () => {
    dispatch(uiToolkit.actions.setTimelineState({ moduleTimelineSelected: !moduleTimelineSelected }));
  };

  const shouldShowModuleTimeline = timelineModule !== undefined || moduleClass !== undefined;

  const handleOpenClick = () => {
    dispatch(currentModuleToolkit.actions.setLinkToTheLastModulePage(location.pathname));
    history.push(shouldShowModuleTimeline && moduleTimelineSelected ? url.module : url.app);
  };

  const service = timelineModule?.service;
  const isServiceAvailable = service !== undefined;

  const {
    getAnnotations,
    addAnnotation: addEntityAnnotation,
    serviceKey,
  } = useMemo(() => {
    if (!isServiceAvailable) {
      return {};
    }
    return {
      getAnnotations: service.getAnnotations?.bind(service),
      addAnnotation: service.addAnnotation?.bind(service),
      serviceKey: service.getFullClassName(),
    };
  }, [isServiceAvailable, service]);

  return (
    <StyledSidebar
      side={SidebarSide.Right}
      manualTogglingParams={timelineManualTogglingParams}
      SlideProps={{
        mountOnEnter: true,
        unmountOnExit: true,
      }}
      ref={sidebarRef}
      $width={intialTimelineWidth.current}
      variant='persistent'
    >
      <Wrapper>
        <Resizable componentRef={sidebarRef} onChange={setSidebarWidth} />
        <ControlBar>
          {shouldShowModuleTimeline ? (
            <Button data-testid='module-switch' onClick={handleModuleToggle} size='small' color='primary'>
              {moduleTimelineSelected ? 'Module' : 'App'}
            </Button>
          ) : (
            <div />
          )}
          <ToolTip title='Search mode' placement='bottom'>
            <IconButton size='small' onClick={handleSearchModeToggle}>
              <SearchIcon fontSize='small' color={searchMode ? 'primary' : 'inherit'} />
            </IconButton>
          </ToolTip>
          {appRouteName && (
            <ToolTip title='Open In Fullscreen' placement='bottom'>
              <IconButton onClick={handleOpenClick} size='small'>
                <FullscreenIcon fontSize='small' />
              </IconButton>
            </ToolTip>
          )}
          <ToolTip title='Refresh' placement='bottom' data-testid='button-refresh'>
            <IconButton onClick={handleRefresh} size='small'>
              <RefreshIcon fontSize='small' />
            </IconButton>
          </ToolTip>
          {isServiceAvailable && getAnnotations && addEntityAnnotation && (
            <ToolTip title='Annotations' placement='bottom'>
              <EntityAnnotations
                getAnnotations={getAnnotations}
                addAnnotation={addEntityAnnotation}
                serviceKey={serviceKey}
                buttonSize={'small'}
              />
            </ToolTip>
          )}
          <ToolTip title='Close' placement='bottom'>
            <IconButton onClick={timelineManualTogglingParams.closeManually} size='small'>
              <CloseIcon fontSize='small' />
            </IconButton>
          </ToolTip>
        </ControlBar>
        {shouldShowModuleTimeline && (
          <TimelineStyled
            key='module-timeline'
            hide={!moduleTimelineSelected}
            state={moduleState}
            onChange={setModuleState}
            onQueryReset={handleQueryReset}
            service={timelineModule?.service ?? notificationsService}
            matchNotification={timelineModule?.matchNotification}
            searchMode={searchMode}
            isEntityTimeline={timelineModule?.isSingle}
            isEntitiesTimeline={true}
          />
        )}
        <TimelineStyled
          key='app-timeline'
          hide={shouldShowModuleTimeline && moduleTimelineSelected}
          state={appModuleState}
          onChange={setAppModuleState}
          onQueryReset={handleQueryReset}
          service={notificationsService}
          searchMode={searchMode}
        />
      </Wrapper>
    </StyledSidebar>
  );
};
