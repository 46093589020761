import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BeachAccess as BeachAccessIcon } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { Tooltip } from '@mui/material';

import { SpaceLessModulesList } from '/constants/SpaceLessModules';
import { activeSpaceToolkit, appToolkit, currentModuleToolkit } from '/store';
import { GlobalSpaceSelect } from '/common/Spaces';

import { ActiveSpace, IconButtonStyled, SpacelessMark } from './styles';

export const SpacePicker = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();
  const app = useSelector(appToolkit.selectors.app);
  const activeSpace = useSelector(activeSpaceToolkit.selectors.activeSpace);
  const location = useLocation();

  const matchedModule = SpaceLessModulesList.find((module) => location.pathname.includes(module));
  const isSpaceLessModule = Boolean(matchedModule);

  useEffect(() => {
    dispatch(currentModuleToolkit.actions.setIsSpaceLessModule(isSpaceLessModule));
  }, [dispatch, isSpaceLessModule, location]);

  const handleOpen = () => {
    if (app !== undefined) {
      setOpenDialog(true);
    }
  };

  const handleClose = useCallback(() => {
    setOpenDialog(false);
  }, []);

  const tooltipTitle = useMemo(() => {
    if (app === undefined) {
      return 'App should be selected.';
    }
    if (isSpaceLessModule && matchedModule) {
      return `The ${matchedModule[0].toUpperCase() + matchedModule.slice(1)} module does not support Space configuration (the selected space will be ignored)`;
    }
    return '';
  }, [app, isSpaceLessModule, matchedModule]);

  return (
    <>
      <Tooltip title={tooltipTitle} placement='bottom'>
        {activeSpace ? (
          <ActiveSpace isSpaceLessModule={isSpaceLessModule} onClick={handleOpen} data-testid='select-space'>
            {isSpaceLessModule && <SpacelessMark activeSpace />}
            {activeSpace.name}
          </ActiveSpace>
        ) : (
          <IconButtonStyled
            color='inherit'
            aria-label='Select Space'
            data-testid='select-space'
            onClick={handleOpen}
            size='large'
            isSpaceLessModule={isSpaceLessModule}
          >
            <BeachAccessIcon />
            {isSpaceLessModule && <SpacelessMark activeSpace={false} data-testid='ignored-space-icon' />}
          </IconButtonStyled>
        )}
      </Tooltip>
      {openDialog && <GlobalSpaceSelect onClose={handleClose} />}
    </>
  );
};
