import { FC, SyntheticEvent, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Divider, IconButton, ListItemIcon, ListSubheader, Menu, MenuItem } from '@mui/material';
import {
  Business as BusinessIcon,
  DeviceHub as DeviceHubIcon,
  Group as GroupIcon,
  Keyboard as CredentialsIcon,
  LibraryBooks as LibraryBooksIcon,
  LocalOffer as TagsManagerIcon,
  Payment as PaymentIcon,
  Policy as PolicyIcon,
  Security as SecurityIcon,
  Send as SendIcon,
  SupervisorAccount as SupervisorAccountIcon,
} from '@mui/icons-material';

import { AccessLevel, PermModule } from '@playq/octopus2-auth';

import { appToolkit, authToolkit } from '/store';

import { StyledLink, StyledListItemText } from './styles';

interface IMenuItem {
  group?: string;
  module?: PermModule;
  label: string;
  icon: JSX.Element;
  route: string;
  disabled?: boolean;
  divider?: boolean;
}

const corpMenuItems: IMenuItem[] = [
  {
    module: PermModule.None,
    label: 'Users',
    icon: <SupervisorAccountIcon />,
    route: '/corp/users',
  },
  {
    module: PermModule.None,
    label: 'Groups',
    icon: <GroupIcon />,
    route: '/corp/groups',
  },
  {
    module: PermModule.None,
    label: 'Permissions',
    icon: <SecurityIcon />,
    route: '/corp/permissions',
  },
  {
    module: PermModule.CredentialsManagement,
    label: 'Credentials',
    icon: <CredentialsIcon />,
    route: '/corp/credentials',
    divider: true,
  },
  {
    group: 'Policies',
    module: PermModule.AssetsPolicyConfiguration,
    label: 'Creative Assets',
    icon: <PolicyIcon />,
    route: '/corp/assets-policy',
  },
  {
    module: PermModule.InventoryPolicyConfiguration,
    label: 'Inventory',
    icon: <PolicyIcon />,
    route: '/corp/inventory-policy',
  },
  {
    module: PermModule.PackagesPolicyConfiguration,
    label: 'Packages',
    icon: <PolicyIcon />,
    route: '/corp/packages-policy',
  },
  {
    module: PermModule.ExperimentsPolicyConfiguration,
    label: 'Experiments',
    icon: <PolicyIcon />,
    route: '/corp/experiments-policy',
    divider: true,
  },
  {
    group: 'AdHawk',
    module: PermModule.Integrations,
    label: 'Integrations',
    icon: <DeviceHubIcon />,
    route: '/corp/integrations',
  },
  {
    module: PermModule.Integrations,
    label: 'WebHooks',
    icon: <SendIcon />,
    route: '/corp/integrations-webhooks',
    divider: true,
  },
  {
    module: PermModule.TagsManagement,
    label: 'Tags Manager',
    icon: <TagsManagerIcon />,
    route: '/corp/tags-manager',
  },
  {
    module: PermModule.WebEventsConfiguration,
    label: 'WebEvents',
    icon: <SendIcon />,
    route: '/corp/webevents',
  },
  {
    module: PermModule.None,
    label: 'Billing',
    icon: <PaymentIcon />,
    route: '/corp/billing',
  },
  {
    module: PermModule.Logs,
    label: 'Logs',
    icon: <LibraryBooksIcon />,
    route: '/corp/logs',
  },
];

export const Corporate: FC = () => {
  const access = useSelector(appToolkit.selectors.accessScope);
  const isAdmin = useSelector(authToolkit.selectors.isAdmin);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | undefined>();
  const handleClick = (event: SyntheticEvent) => {
    const target = event.currentTarget as HTMLButtonElement;
    setAnchorEl(target);
  };

  const handleClose = (item?: IMenuItem) => (event: SyntheticEvent & { metaKey?: boolean }) => {
    if (event.metaKey) {
      return;
    }
    if (item?.disabled) {
      event.preventDefault();
      return;
    }
    setAnchorEl(undefined);
  };

  const menuItems = useMemo(() => {
    return corpMenuItems.filter((item) => {
      if (!item.module) {
        return true;
      }
      const moduleAccess = access[item.module];
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      return isAdmin || (!!moduleAccess && moduleAccess !== AccessLevel.None);
    });
  }, [access, isAdmin]);

  return menuItems.length ? (
    <>
      <IconButton
        color='inherit'
        aria-owns={anchorEl ? 'simple-menu' : undefined}
        aria-haspopup='true'
        aria-label='Corporate section'
        data-testid='button-corp-section'
        onClick={handleClick}
        size='large'
      >
        <BusinessIcon />
      </IconButton>
      <Menu id='simple-menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose()}>
        {menuItems.map((item, idx) => [
          item.group && <ListSubheader>{item.group}</ListSubheader>,
          <StyledLink key={idx} onClick={handleClose(item)} to={item.route}>
            <MenuItem disabled={item.disabled} data-testid={`${item.label.toLowerCase()}-button`}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <StyledListItemText primary={item.label} />
            </MenuItem>
          </StyledLink>,
          item.divider && (
            <Divider
              sx={{
                opacity: '0.6',
              }}
            />
          ),
        ])}
      </Menu>
    </>
  ) : null;
};
