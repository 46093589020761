import { Card, CardContent, CardHeader, styled } from '@mui/material';

import { collapsedNavSidebarWidth, navSidebarWidth } from '../NavSidebar';
import { headerHeight } from '../Header/styles';

const helperHeaderHeight = '80px';

const helperHalfHeight = '50vh';
const helperFullHeight = `calc(100vh - ${headerHeight}px)`;

const contentHalfHeight = `calc(${helperHalfHeight} - ${helperHeaderHeight})`;
const contentFullHeight = `calc(${helperFullHeight} - ${helperHeaderHeight})`;

export const StyledHelper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'navSidebarOpen', // Prevent passing this prop to DOM
})<{ navSidebarOpen: boolean }>(({ theme, navSidebarOpen }) => ({
  position: 'fixed',
  bottom: 0,
  right: 0,
  zIndex: theme.zIndex.modal,
  borderRadius: theme.spacing(0.5),
  margin: '0 1px',
  boxShadow:
    'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
  left: navSidebarOpen ? `${navSidebarWidth}px` : `${collapsedNavSidebarWidth}px`,
}));

export const HelperCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'expanded', // Prevent passing this prop to DOM
})<{ expanded: boolean }>(({ theme, expanded }) => ({
  boxShadow: 'none',
  transition: `${theme.transitions.create('height', {
    duration: expanded ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
    easing: theme.transitions.easing.sharp,
  })} !important`,
  height: expanded ? helperFullHeight : helperHalfHeight,
}));

export const HelperCardHeader = styled(CardHeader)(({ theme }) => ({
  // Styles for the root
  '&.MuiCardHeader-root': {
    height: helperHeaderHeight,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  // Styles for the title
  '& .MuiCardHeader-title': {
    display: 'grid',
    gridTemplateColumns: '30px auto',
    alignItems: 'center',
  },
}));

export const HelperCardContent = styled(CardContent, {
  shouldForwardProp: (prop) => prop !== 'expanded', // Prevent passing this prop to DOM
})<{ expanded: boolean }>(({ expanded }) => ({
  paddingTop: 0,
  overflow: 'auto',
  height: expanded ? contentFullHeight : contentHalfHeight,
}));
