import { FC, memo, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Subject } from 'rxjs';
import { Box, Typography, useEventCallback } from '@mui/material';
import { VirtuosoHandle } from 'react-virtuoso';
import * as d3 from 'd3';

import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import { OptionsFilter, TextFilter } from '@playq/octopus-common';
import {
  AdminNotificationHelpers,
  MutationNotificationHelpers,
  Notification,
  NotificationsFilterField,
  NotificationsResponse,
  NotificationWithAnnotations,
  UserAnnotation,
  UsersTagHelpers,
} from '@playq/octopus-notifications';

import { formatDate } from '/helpers';
import { useAddNotificationAnnotation, useQueryTimeline } from '/api/hooks/timeline';
import { appToolkit, authToolkit } from '/store';
import { buzzerAppNotifications$, BuzzerEntityNotifications, BuzzerNotifications } from '/common/buzzer';
import { snackbarService } from '/common/snackbarService';

import { TimelineProps, TimelineState } from './types';
import { nonSystemNotificationClasses } from './ClassPicker';
import { NewNotificationsButton } from './NewNotificationsButton';
import { FilterBar, NONE, timeGroupUnits } from './FilterBar';
import { SkeletonTimeline } from './SkeletonTimeline';
import { AppTags } from './AppTags';
import { NotificationGroup, NotificationRecord, NotificationRow } from './NotificationRow';
import { List, NotFoundTextWrapper, Wrapper } from './styles';

const moduleNotifications$ = new Subject<NotificationWithAnnotations[]>();

const colorScale = d3.scaleOrdinal(d3.schemeTableau10);

export const NOTIFICATION_HEIGHT = 60;

const getGroupKey = (notification: NotificationWithAnnotations, groupBy: string): string => {
  if (timeGroupUnits.includes(groupBy)) {
    let dateFormat = 'EEE DDD tt';
    switch (groupBy) {
      case 'Hour':
        dateFormat = 'HH:00, DDD';
        break;
      case 'Day':
        dateFormat = 'DDD';
        break;
      case 'Month':
        dateFormat = 'LLLL y';
        break;
      case 'Year':
        dateFormat = 'y';
        break;
    }
    return formatDate(notification.notification.at, dateFormat);
  }
  return notification.notification.class_;
};

export const initialIteratorSerialized = {
  offset: 0,
  limit: 20,
};

export const getInitialIterator = () => new OffsetLimit(initialIteratorSerialized);

export const getInitialState = (removeClasses?: boolean): TimelineState => ({
  query: {
    iterator: getInitialIterator(),
    sortBy: [],
    filterBy: {
      ...(removeClasses
        ? {}
        : {
            [NotificationsFilterField.Class]: new OptionsFilter({
              options: nonSystemNotificationClasses,
            }),
          }),
    },
  },
  groupBy: NONE,
  expandedItems: [],
});

export const Timeline: FC<TimelineProps> = memo(
  ({
    state,
    onChange,
    onQueryReset,
    service,
    matchNotification,
    isEntityTimeline,
    isEntitiesTimeline,
    searchMode,
    fullscreen,
    className,
    style,
    skeletonSize,
    widgetMode,
    notifications,
    maxTimelineHeight,
  }) => {
    const appID = useSelector(appToolkit.selectors.appID);
    const user = useSelector(authToolkit.selectors.user);
    const isAdmin = useSelector(authToolkit.selectors.isAdmin);

    const [newNotifications, setNewNotifications] = useState<Notification[]>([]);
    const [data, setData] = useState<NotificationsResponse | undefined>();
    const listRef = useRef<VirtuosoHandle>(null);
    const loadingNext = useRef(false);

    const { error, isLoading, mutate } = useQueryTimeline(
      service,
      appID,
      state.query.iterator,
      state.query.sortBy,
      state.query.filterBy,
      {
        onSuccess: (newData) => {
          loadingNext.current = false;
          const shouldReplace = !newData || !data || newData.iterator.offset === 0;
          if (shouldReplace) {
            setData(newData);
            listRef.current?.scrollToIndex(0);
            return;
          }
          data.notifications.push(...newData.notifications);
        },
        enabled: !widgetMode,
      }
    );

    const { mutate: addAnnotation } = useAddNotificationAnnotation({
      onMutate: ({ notificationID, message }) => {
        if (user) {
          const now = new Date();
          const userAnnotation = new UserAnnotation();
          userAnnotation.atAsString = now.toISOString();
          userAnnotation.at = now;
          userAnnotation.message = message;
          userAnnotation.userID = user.id;
          userAnnotation.userName = user.name;

          const notificationsResponse = new NotificationsResponse();
          notificationsResponse.notifications = (data?.notifications || []).map((n) => {
            if (n.id === notificationID) {
              const notification = new NotificationWithAnnotations();
              notification.id = n.id;
              notification.notification = n.notification;
              notification.annotations = [userAnnotation, ...n.annotations];
              return notification;
            }
            return n;
          });
          mutate(notificationsResponse);
        }
        return data;
      },
      onError: (requestError, _payload, captured) => {
        if (captured) {
          mutate(captured);
        }
        snackbarService.genericFailure(requestError);
      },
    });

    const records: NotificationRecord[] = useMemo(() => {
      if (notifications !== undefined) {
        return notifications;
      }
      if (data) {
        if (state.groupBy === NONE) {
          return data.notifications;
        }
        const notificationsByKey: Record<string, NotificationWithAnnotations[]> = {};

        data.notifications.forEach((notification) => {
          const key = getGroupKey(notification, state.groupBy);
          if (notificationsByKey[key] === undefined) {
            notificationsByKey[key] = [];
          }
          notificationsByKey[key].push(notification);
        });
        const keys =
          state.groupBy === 'Class' ? Object.keys(notificationsByKey).sort() : Object.keys(notificationsByKey);
        const groupedItems: NotificationRecord[] = [];
        for (const key of keys) {
          const groupedItem = {
            id: key,
            title: key,
            items: notificationsByKey[key],
            color: d3.color(colorScale(key))?.hex(),
          };
          groupedItems.push(groupedItem);
          if (state.expandedItems.includes(groupedItem.id)) {
            for (const item of groupedItem.items) {
              groupedItems.push(item);
            }
          }
        }
        return groupedItems;
      }
      return [];
    }, [notifications, data, state.groupBy, state.expandedItems]);

    const filterByClass = useMemo(
      () => state.query.filterBy[NotificationsFilterField.Class] as OptionsFilter,
      [state.query.filterBy]
    );

    useEffect(() => {
      const sub = moduleNotifications$.asObservable().subscribe((moduleNotifications) => {
        if (!isEntitiesTimeline) {
          const notificationsList = moduleNotifications.filter((n) =>
            filterByClass.options.includes(n.notification.class_)
          );
          if (notificationsList.length > 0) {
            const notificationsResponse = new NotificationsResponse();
            notificationsResponse.notifications = notificationsList.concat(data?.notifications || []);
            setNewNotifications([]);
            mutate(notificationsResponse);
          }
        }
      });

      return () => {
        sub.unsubscribe();
      };
    }, [filterByClass, isEntitiesTimeline, data?.notifications, mutate]);

    useEffect(() => {
      const sub = buzzerAppNotifications$.subscribe((notification: BuzzerNotifications) => {
        if (
          !searchMode ||
          UsersTagHelpers.isInstanceOf(notification) ||
          MutationNotificationHelpers.isInstanceOf(notification) ||
          (isAdmin && AdminNotificationHelpers.isInstanceOf(notification))
        ) {
          const n = notification as BuzzerEntityNotifications;
          if (n.userID.serialize() !== user?.id.serialize()) {
            if (!isEntitiesTimeline) {
              if (filterByClass.options.includes(n.class_)) {
                setNewNotifications((prevState) => prevState.concat(n));
              }
            } else {
              if (matchNotification?.(n)) {
                setNewNotifications((prevState) => prevState.concat(n));
              }
            }
          }
        }
      });
      return () => {
        sub.unsubscribe();
        onChange((prevState) => ({
          ...prevState,
          query: {
            ...prevState.query,
            iterator: getInitialIterator(),
          },
        }));
      };
    }, [isAdmin, user, filterByClass, searchMode, isEntitiesTimeline, matchNotification, onChange]);

    useEffect(() => {
      setNewNotifications((prevState) => (prevState.length > 0 ? [] : prevState));
    }, [state, searchMode]);

    const errorMsg = useMemo(() => {
      if (error) {
        if (error instanceof GenericFailure) {
          return `${error.message}. Code: ${error.code}`;
        }
        return error.message;
      }
    }, [error]);

    const canAddAppTags = useMemo(() => {
      return !isEntitiesTimeline && !isLoading && state.groupBy === NONE;
    }, [isEntitiesTimeline, state.groupBy, isLoading]);

    const handleGroupByChange = useEventCallback((groupBy: string) => {
      onChange((prevState) => ({
        ...prevState,
        groupBy,
        expandedItems: [],
      }));
    });

    const handleQueryChange = useEventCallback((query: SetStateAction<TimelineState['query']>) => {
      listRef.current?.scrollTo({ top: 0 });
      setData(undefined);
      onChange((prevState) => ({
        ...prevState,
        query: {
          ...(typeof query === 'function' ? query(prevState.query) : query),
          iterator: getInitialIterator(),
        },
      }));
    });

    const handleAnnotationSubmit = useEventCallback((notificationID: number, annotation: string) => {
      addAnnotation({ notificationID, message: annotation });
    });

    const handleToggleGroupedItem = useEventCallback((group: NotificationGroup) => {
      onChange((prevState) => {
        if (!prevState.expandedItems.includes(group.title)) {
          return {
            ...prevState,
            expandedItems: [...prevState.expandedItems, group.title],
          };
        } else {
          return {
            ...prevState,
            expandedItems: prevState.expandedItems.filter((id) => id !== group.title),
          };
        }
      });
    });

    const handleUserSelect = useEventCallback((author: string) => {
      onChange((prevQuery) => ({
        ...prevQuery,
        query: {
          ...prevQuery.query,
          filterBy: {
            ...prevQuery.query.filterBy,
            [NotificationsFilterField.Author]: new TextFilter({
              text: author,
            }),
          },
        },
      }));
    });

    const loadNewNotification = () => {
      const notificationsWithAnnotations = newNotifications.reverse().map((newNotification) => {
        const notificationWithAnnotation = new NotificationWithAnnotations();
        notificationWithAnnotation.id = parseInt(newNotification.at.getTime().toString().slice(-7), 10);
        notificationWithAnnotation.annotations = [];
        notificationWithAnnotation.notification = newNotification;
        return notificationWithAnnotation;
      });
      const notificationsResponse = new NotificationsResponse();
      notificationsResponse.notifications = notificationsWithAnnotations.concat(data?.notifications || []);
      mutate(notificationsResponse);
      setNewNotifications([]);
      if (isEntitiesTimeline) {
        moduleNotifications$.next(notificationsWithAnnotations);
      }
    };

    const handleItemsRerender = (index: number) => {
      if (hasMore && index >= records.length - 2 && !loadingNext.current) {
        loadingNext.current = true;
        onChange((prevQuery) => ({
          ...prevQuery,
          query: {
            ...prevQuery.query,
            iterator: new OffsetLimit({
              offset: prevQuery.query.iterator.limit + prevQuery.query.iterator.offset,
              limit: 30,
            }),
          },
        }));
      }
    };

    const handleReset = () => {
      setData(undefined);
      onQueryReset();
      listRef.current?.scrollTo({ top: 0 });
    };

    const recordsLength = useMemo(
      () =>
        records.reduce((acc, record) => {
          if ('items' in record) {
            return acc + record.items.length;
          }
          return acc + 1;
        }, 0),
      [records]
    );

    const hasMore = recordsLength !== data?.total;

    return (
      <Wrapper className={className} style={style}>
        <NewNotificationsButton notificationsCount={newNotifications.length} onClick={loadNewNotification} />
        <FilterBar
          query={state.query}
          onChange={handleQueryChange}
          onReset={handleReset}
          groupBy={state.groupBy}
          onGroupByChange={handleGroupByChange}
          isEntitiesTimeline={isEntitiesTimeline}
          expanded={fullscreen}
          hide={!searchMode}
        />
        {(!notifications && widgetMode) || (isLoading && !widgetMode) ? (
          <SkeletonTimeline ltr={fullscreen} height={NOTIFICATION_HEIGHT} arrayLength={skeletonSize} />
        ) : errorMsg ? (
          <Box color='error.main'>{errorMsg}</Box>
        ) : records.length > 0 ? (
          <List
            ref={listRef}
            data-testid='timeline-module'
            widgetMode={widgetMode}
            maxTimelineHeight={maxTimelineHeight}
            totalCount={loadingNext.current ? records.length + 1 : records.length}
            endReached={handleItemsRerender}
            itemContent={(index) => (
              <NotificationRow
                index={index}
                data={{
                  records,
                  expandedItems: state.expandedItems,
                  onToggle: handleToggleGroupedItem,
                  onAnnotationSubmit: handleAnnotationSubmit,
                  onUserSelect: handleUserSelect,
                  isEntityTimeline,
                  fullscreen,
                }}
              />
            )}
          />
        ) : (
          <NotFoundTextWrapper>
            <Typography align='center' variant='h6'>
              No Data Found
            </Typography>
          </NotFoundTextWrapper>
        )}
        {canAddAppTags && <AppTags />}
      </Wrapper>
    );
  }
);
